import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/ui/breadcrumbs/Breadcrumbs";
import styles from "./styles.module.scss";
import { ProductType } from "../../types";
import { Input } from "../../components/input/Input";
import { Button, BUTTON_COLORS } from "../../components/button/Button";
import { SEARCH_COMPONENTS } from "../../constants/enums";
import ProductInfo from "../../components/product-info/ProductInfo";
import { ReactComponent as sort } from "../../static/assets/svg/Sort descending.svg";
import { ReactComponent as refresh } from "../../static/assets/svg/refresh.svg";
import { ReactComponent as closeIcon } from "../../static/assets/svg/x.svg";
import MaterialsTable from "../../components/materials-table/MaterialsTable";
import {
  deleteCertificatesFetcher,
  downloadZip,
  findProductCertificateFetcher,
  manufacturerDocFetcher,
  productReviewNotification,
  scrappingFetcher,
  sendRohsMail,
} from "../../api/SWR/fetchers";
import { toast } from "react-toastify";
import useUser from "../../api/SWR/useUser";
import EditProductModalContainer from "../../components/edit-product-modal/EditProductModalContainer";
import CustomModal from "../../components/custom-modal/CustomModal";
import AddMaterial from "../../components/add-material/AddMaterial";
import { materialAPI, productApi } from "../../api/apiRequests";
import axios from "axios";
import { getAccessToken } from "../../api/utils";
import { CERTIFICATE_COLLECTION_STATUSES } from "../../constants/enums";
import { receiverEmails } from "../../api/constants";

type ProductPagePropsTypes = {
  product: ProductType;
  scrapeListForSearch: any;
  wsCertificates: any;
  mutate: any;
  isDetect: any;
  setIsDetect: any;
  setWsCertificates: any;
};

const ProductPage = ({
  product,
  scrapeListForSearch,
  setIsDetect,
  isDetect,
  wsCertificates,
  mutate,
  setWsCertificates,
}: ProductPagePropsTypes) => {
  const breadcrumbsPages = [
    { name: "Products", href: "/products", current: false },
    { name: product.name, href: "#", current: true },
  ];
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDownloadCert, setisDownloadCert] = useState(false);
  const [productToEdit, setProductToEdit] = useState<boolean | null>(null);
  const [isAddMaterialModalShown, setIsAddMaterialModalShown] = useState<
    number | null
  >(null);
  const [callbackState, setCallbackState] = useState<object>({});
  const [searchDD, setSearchDDValue] = useState<any>(SEARCH_COMPONENTS.type);
  const [searchValue, setSearchValue] = useState<string>("");
  const [revertProductStatus, setRevertProductStatus] = useState({
    popup: false,
  });
  const [materialsToDelete, setMaterialsToDelete] = useState([]);
  const [rohsPopUp, setRohsPopUp] = useState<boolean>(false);
  // import { receiverEmails } from "../../api/constants";
  // import { manufacturerMails, sendRohsMail } from "../../api/SWR/fetchers";

  const [roshLoading, setRoshLoading] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  // const [productStatusId, setProductStatusId] = useState<number>(
  //   product?.status.id || CERTIFICATE_COLLECTION_STATUSES.PENDING
  // );
  const [productStatusId, setProductStatusId] = useState<number>(
    (product && product.status && product.status.id) ||
      CERTIFICATE_COLLECTION_STATUSES.PENDING
  );
  const [isScrappingLoading, setIsScrappingLoading] = useState(false);
  // Logic for setting the loading prop for the Start scraping button.
  useEffect(() => {
    console.log(wsCertificates, "this is the main wsCerts");
  }, [wsCertificates]);
  const checkLoader = (loading: boolean) => {
    setIsScrappingLoading(loading);
  };
  const { data } = useUser();
  const isAdmin = data.role === 1;
  const useRole = data.role;
  const handleDeleteMaterials = async () => {
    try {
      setIsLoading(true);
      await materialAPI.deleteMaterials(product.id, materialsToDelete);
      setIsLoading(false);
      await mutate();
      toast.success("Selected materials deleted");
    } catch (e) {
      toast.error("Something went wrong");
      setIsLoading(false);
    } finally {
    }
  };
  const searchHandler = (e: any) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      console.log("-----====////////>", e.target.value);

      setCallbackState({});
    }
  };
  const handleSelectChange = (e: any) => {
    console.log("======> ", e.target.value);
    setSearchDDValue(e.target.value);
  };
  const submittSearch = () => {
    if (searchValue.length == 0) {
      // alert("Write something to search")
      toast.error("Write Something to Search");
      return;
    }
    const regex = /[.,\/#%*+\s-]/g;
    const query = searchValue.replace(regex, "");
    const tempMap = {
      type: searchDD,
      query: query.trim().toLowerCase(),
    };
    setCallbackState(tempMap);
  };
  const sendRoHSMail = async () => {
    let customRoHSText = `Dear Sir/Madam, <br/>
    We are requesting RoHS declaration on behalf of ${data?.firstName} ${
      data?.lastName
    }, at ${data?.company?.name ? data?.company?.name : "Company"}, ${
      data?.company?.name ? data?.company?.name : "Company"
    } are using or intends to use your products  manufactured by myManufacturer. <br/>
          We are requesting RoHS declaration(s) for the following products: <br/>
          myComponent <br/>
          If we are reaching out to an incorrect email address for this purpose, please reply to us or forward this to the proper recipient. <br/>
          Thank you for your attention to this matter. <br/>
          Best Regards,
    `;
    try {
      setRoshLoading(true);
      let res = await sendRohsMail({
        id: product.id,
        token: getAccessToken(),
        mailInfo: {
          ccMails: receiverEmails.ccEmails,
          htmlContent: customRoHSText,
        },
      });

      if (res.result) {
        toast.success("Mails Sent Successfully!");
      }
      await mutate();
      setRoshLoading(false);
      setRohsPopUp(false);
    } catch (error) {
      setRoshLoading(false);
      toast.error("Something Went Wrong!");
    }
  };

  const downloadProductZip = async () => {
    toast.info("Downloading!");
    setisDownloadCert(true);
    try {
      await downloadZip(product.id)
        .then((response) => {
          // Create a URL object from the response data
          const url = window.URL.createObjectURL(new Blob([response]));

          // Create a temporary <a> element and set its attributes
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${product.name}-${product.id}.zip`); // Set the desired filename for the downloaded file

          // Append the link to the document body and click it programmatically to trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up the URL object and remove the temporary link element
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error: any) => {
          // Handle any errors that occur during the download process
          console.error("Error downloading zip:", error);
        });
      setisDownloadCert(false);
    } catch (e) {
      console.log(e);
    } finally {
      setisDownloadCert(false);
    }
  };

  const handleStartScraping = async () => {
    toast.info("Scraping is started!");
    try {
      const copyArr = scrapeListForSearch.map((item: any) => ({
        ...item,
        manufacturer: "",
      }));
      const mergedArr = [...scrapeListForSearch, ...copyArr];

      console.log(mergedArr, "merged ARRAY");

      setIsScrappingLoading(true);
      // console.log(mergedArr,"here list");
      // await scrappingFetcher(mergedArr);
      await manufacturerDocFetcher(product.materials);
      await findProductCertificateFetcher(product.materials);
      // await handleRanProductsStart(product.materials);
      console.log("Materials", product.materials);
      setIsScrappingLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setIsScrappingLoading(false);
  }, [callbackState]);

  const createRohsView = () => {
    return (
      <>
        <div>
          <h3 className="text-2xl leading-10 font-medium mb-2.5 text-primary-500 mx-10">
            Activate RoHS DoC collection
          </h3>
        </div>
        <h3 className="mx-10 text-2xs leading-7 mb-2.5 text-gray-500">
          Confirming this will activate the collection of RoHS documentation for
          components in this product. <br /> This entails sending out emails
          requesting documentation from all manufacturers.
        </h3>
        <div className={"flex flex-col"}>
          <div className={"flex ml-auto mt-4"}>
            <Button
              color="primary"
              label={"Cancel"}
              onClick={() => {
                setRohsPopUp(false);
              }}
            />

            <Button
              color="primary"
              className="ml-3 mb-5 mr-5"
              label={"Confirm"}
              loading={roshLoading}
              onClick={() => {
                sendRoHSMail();
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const handleRanProductsStart = async (products: any) => {
    let data: any;
    products.forEach(async (product: any) => {
      console.log(product);

      if (product.ranProducts) {
        data = JSON.parse(product.ranProducts);
        console.log(product.componentName, "item here");

        let index1 = data.findIndex(
          (item: any) =>
            item.model === product.componentName &&
            item.manufacture === product.manufacturer &&
            item.isRunning === false
        );
        let index2 = data.findIndex(
          (item: any) =>
            item.model === product.componentName &&
            item.manufacture === "" &&
            item.isRunning === false
        );

        // console.log(item,"data is here");
        if (index1 !== -1) {
          data[index1].isRunning = true;
        }
        if (index2 !== -1) {
          data[index2].isRunning = true;
        }
        console.log(index1, index2, "data here");
        if (index1 !== -1 || index2 !== -1) {
          console.log(data, "my data here");

          handleRanProducts(product.id, JSON.stringify(data));
          data = null;
        }

        // if (!item) {
        //   data.push({
        //     model: product.search,
        //     manufacture: product.manufacturer,
        //     isRunning: true,
        //   });
        // }
      } else {
        if (!data) {
          data = [
            {
              model: product.componentName,
              manufacture: product.manufacturer,
              isRunning: true,
            },
            {
              model: product.componentName,
              manufacture: "",
              isRunning: true,
            },
          ];
          console.log("Data is here", data, product.id);

          handleRanProducts(product.id, JSON.stringify(data));
          data = null;
        }
      }
    });
    console.log(data, "data is here");
  };
  const handleRanProducts = async (id: number, value: string) => {
    const res = await productApi.updateMaterialRanProducts(id, value);
    mutate();
  };
  const handleDeleteCertificates = async () => {
    const materialsToDeleteIdArray = product.materials.map(
      (material: { id: any }) => material.id
    );
    try {
      await deleteCertificatesFetcher({
        materialIds: materialsToDeleteIdArray,
      });
      setWsCertificates([]);
      await mutate();
      toast.success("Certificates deleted");
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleChangeEditMaterialMode = async (isEditMode: boolean) => {
    if (isEditMode) {
      await mutate();
    }

    setIsEditMode(!isEditMode);
  };

  const user = useUser();
  const collectRoHSCertificates = async () => {
    console.log(product.isRoHSAvailable, isAdmin, user.data.isRoHSAllow);

    setRohsPopUp(true);
  };

  const changeProductStatus = async () => {
    setIsLoading(true);
    if (
      productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING ||
      productStatusId === CERTIFICATE_COLLECTION_STATUSES.DONE
    ) {
      // approved product -> ready for review
      await productApi.updateProductStatus(
        product?.id,
        CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
      );
      await productReviewNotification({
        id: product?.id,
      });
      setProductStatusId(CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW);
    } else {
      // in review product -> pending
      await productApi.updateProductStatus(
        product?.id,
        CERTIFICATE_COLLECTION_STATUSES.DONE
      );
      setProductStatusId(CERTIFICATE_COLLECTION_STATUSES.DONE);
    }
    await mutate();
    setIsLoading(false);
  };
  const SelectComponent = ({ value, onChange }: any) => {
    return (
      <td className="whitespace-nowrap align-top text-sm text-gray-500">
        {
          <select
            className="component-select rounded py-1.5 border border-gray-200 w-24 mr-1.5"
            // className={styles.componentSelect}
            onChange={onChange}
            value={value}
            name="componentStatus"
            id="component-status"
          >
            <option value={SEARCH_COMPONENTS.type}>TYPE</option>
            <option value={SEARCH_COMPONENTS.internalReference}>
              INTERNAL COMPONENT REFERENCE
            </option>
            <option value={SEARCH_COMPONENTS.componentName}>
              COMPONENT NAME
            </option>
            <option value={SEARCH_COMPONENTS.manufacturer}>MANUFACTURER</option>
          </select>
        }
      </td>
    );
  };
  const changeProductStatusByCustomerHandler = (productId: number) => {
    console.log(productId, "pproducct id");

    productApi
      .customerProductStatus(
        productId,
        productStatusId == 2
          ? CERTIFICATE_COLLECTION_STATUSES.DONE
          : CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
      )
      .then((response: any) => {
        if (response) {
          toast.success("Product status update successfully");
          setRevertProductStatus({ popup: false });
          setProductStatusId(
            productStatusId == 2
              ? CERTIFICATE_COLLECTION_STATUSES.DONE
              : CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
          );

          mutate();
        } else {
          toast.error("Product not delete please try again.");
          setRevertProductStatus({ popup: false });
          console.log("product not delete");
        }
      });
  };
  const changeProductStatusByCustomerPopup = () => {
    return (
      <>
        <div className="flex flex-col gap-10 px-10 py-5">
          <div>
            <h3 className="text-2xl leading-10  font-medium text-primary-500 text-start mb-5">
              Product Status
            </h3>
            <p className="text-start">
              {productStatusId == 2
                ? 'Are you sure that you want to set the status from In Review to "Approved"'
                : 'Are you sure that you want to revert the status from Approved to “Ready for review"'}
              ?
            </p>
          </div>

          <div className="flex justify-end gap-2">
            <Button
              color="gray"
              className="border-2 items-center flex"
              label="NO"
              onClick={() => setRevertProductStatus({ popup: false })}
            />
            <Button
              label="Yes"
              onClick={() => changeProductStatusByCustomerHandler(product?.id)}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={styles.wrapper}>
      <div className="flex justify-between items-center mb-7">
        <Breadcrumbs pages={breadcrumbsPages} />
        <div>
          {/*   // OCT-18 2023  ROHS code commented because client not want it right now */}
          {/* <Button
                className="mt-4 mb-4 mr-2"
                disabled={
                  
                  !(( product.isRoHSAvailable) && ( isAdmin || user.data.isRoHSAllow ))
                }
                label={`${"Collect RoHS"}`}
                onClick={collectRoHSCertificates}
                loading={isLoading}
              /> */}
          {/* {isAdmin && productStatusId && (
            <Button
            className="mr-2"
              label={`${
                productStatusId === CERTIFICATE_COLLECTION_STATUSES.PENDING
                  ? "Approve certificate list"
                  : "Set status to In Review"
              }`}
              onClick={changeProductStatus}
              loading={isLoading}
            />
          )} */}

          {!isAdmin && (productStatusId === 4 || productStatusId === 2) && (
            <Button
              className="mr-2"
              label={
                productStatusId === 2
                  ? "Approve Product"
                  : "Revert from Approved"
              }
              onClick={() => setRevertProductStatus({ popup: true })}
              // loading={isLoading}
            />
          )}
          {isAdmin && productStatusId && (
            <Button
              className="mr-2"
              label={`${
                productStatusId ===
                CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
                  ? "Approve certificate list"
                  : "Set status to In Review"
              }`}
              onClick={changeProductStatus}
              loading={isLoading}
            />
          )}
          <Button
            label="Edit product"
            color={BUTTON_COLORS.normal}
            onClick={() => setProductToEdit(true)}
          />
        </div>
      </div>

      <div className={styles.productInfoWrapper}>
        <ProductInfo
          isRoHSAvailable={product.isRoHSAvailable}
          manufacturer={product?.manufacturer}
          id={product?.id}
          title={product?.name}
          description={product?.description}
          markets={product?.markets}
          type={product?.type}
          standards={product?.standards}
          developmentStatus={product?.developmentStatus}
          customer={product?.customer}
          status={product?.status}
          mutate={mutate}
          materials={product.materials}
          productCreatedDate={product.createdAt}
          productApprovalDate={product.productApprovalDate}
          setCallbackState={setCallbackState}
        />
      </div>
      <div className={"App__container"}>
        <div className={"flex justify-between items-center mb-2.5"}>
          <div>
            <h2 className="text-lg leading-7 font-medium text-gray-900">
              Bill of Materials
            </h2>
            <p className={"text-sm leading-5 font-normal text-gray-500"}>
              Retrieved from bom.xlsx
            </p>
            <div className="flex">
              {SelectComponent({
                value: searchDD,
                onChange: handleSelectChange,
              })}
              <Input
                style={{ width: "250px" }}
                value={searchValue}
                onChange={searchHandler}
                placeholder="Search"
                inputValue={searchValue}
                handleClearInput={() => {
                  setCallbackState({});
                  setSearchValue("");
                }}
              ></Input>
              <Button
                label="Search"
                className={"mr-2.5, ml-1.5"}
                onClick={submittSearch}
              />
            </div>
          </div>

          <div className={"flex  justify-end  gap-2"}>
            {/* flex-wrap */}
            <div className={"flex flex-col gap-2"}>
              <div className={"flex justify-end"}>
                {isAdmin ? (
                  <Button
                    label="Start scraping"
                    icon={sort}
                    // className={"mr-2.5"}
                    // loading={isScrappingLoading}
                    onClick={handleStartScraping}
                  />
                ) : null}
              </div>

              {/* <Button
              label="Refresh list"
              icon={refresh}
              color={BUTTON_COLORS.normal}
              onClick={() => mutate()}
            /> */}
              {/* {
              isAdmin &&

              <Button
                label="Delete certificates"
                icon={closeIcon}
                color={BUTTON_COLORS.normal}
                onClick={handleDeleteCertificates}
                disabled={
                  !product.materials
                    .map((material) =>
                      material?.certificates?.some(
                        (certificates: any) => certificates.id
                      )
                    )
                    .includes(true) && wsCertificates.length === 0
                }
              />
            } */}
              <div>
                <Button
                  loading={isDownloadCert}
                  label="Download zip"
                  onClick={() => downloadProductZip()}
                  className={"mr-1.5"}
                />

                {isAdmin && (
                  <Button
                    label={
                      isEditMode ? "Turn off edit mode" : "Turn on edit mode"
                    }
                    onClick={() => handleChangeEditMaterialMode(isEditMode)}
                    className={"mr-1.5"}
                  />
                )}
                <Button
                  label="Add component"
                  onClick={() => setIsAddMaterialModalShown(product.id)}
                  className={"mr-1.5"}
                />
                {isAdmin && (
                  <Button
                    label={"Delete components"}
                    disabled={materialsToDelete.length <= 0 || isLoading}
                    onClick={() => handleDeleteMaterials()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <MaterialsTable
          productId={product.id}
          productStatus={product.status}
          isDetect={isDetect}
          setIsDetect={setIsDetect}
          mutate={mutate}
          materials={product.materials}
          wsCertificates={wsCertificates}
          setMaterialsToDelete={setMaterialsToDelete}
          materialsToDelete={materialsToDelete}
          isEditMode={isEditMode}
          mainPageLoader={(event: boolean) => checkLoader(event)}
          callbackSearchValue={callbackState}
        />
      </div>
      {productToEdit && (
        <EditProductModalContainer
          productID={product.id}
          setProductToEdit={setProductToEdit}
          mutate={mutate}
        />
      )}
      <CustomModal
        setModalShown={() => setIsAddMaterialModalShown(null)}
        isShown={!!isAddMaterialModalShown}
      >
        <AddMaterial
          productID={isAddMaterialModalShown}
          mutate={mutate}
          closeModal={() => setIsAddMaterialModalShown(null)}
        />
      </CustomModal>
      <CustomModal
        isShown={rohsPopUp}
        setModalShown={() => {
          setRohsPopUp(false);
        }}
      >
        {createRohsView()}
      </CustomModal>
      <CustomModal
        isShown={revertProductStatus.popup}
        setModalShown={() => {
          setRevertProductStatus({ popup: false });
        }}
      >
        {changeProductStatusByCustomerPopup()}
      </CustomModal>
    </div>
  );
};

export default ProductPage;
