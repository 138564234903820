import axios from "axios";
import { baseUrl, scrappingUrl } from "./constants";

export const axiosInstance = axios.create({
  timeout: 1000000,
  baseURL: baseUrl,
});
export const axiosScraperInstance = axios.create({
  baseURL: scrappingUrl,
});
