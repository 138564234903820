import React, { FC } from "react";

import { useLocation } from "react-router-dom";
import Header from "../../components/header/Header";

const RootLayout: FC = ({ children }) => {
  const location = useLocation();

  const titleRender = () => {
    switch (true) {
      case location.pathname === "/404":
        return "Not found";
      case location.pathname === "/products":
        return "Products";
      case location.pathname === "/components":
        return "Components";
        case location.pathname === "/documents":
        return "Documents";
      default:
        return "";
    }
  };

  return (
    <>
      <header className='Header__wrapper'>
        <Header
          title={titleRender()}
          dropItems={[{ label: "Sign out", path: "/sign-out" }]}
          navItems={[
            { label: "Products", path: "/products" },
            { label: "Components", path: "/components" },
            { label: "Manufacturer", path: "/manufacturer" },
            // { label: "Documents", path: "/documents" },

          ]}
          mainPath={"/"}
        />
      </header>

      <div className='App__wrapper'>
        <div className='App__container'>
            {children}
        </div>
      </div>
    </>
  );
};

export default RootLayout;
