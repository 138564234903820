export enum CERTIFICATE_COLLECTION_STATUSES {
  PENDING = 1,
  READY_FOR_REVIEW = 2,
  COLLECTIONS_ADDITIONAL_CERTIFICATES = 3,
  DONE = 4,
}
export enum COMPONENT_STATUSES {
  IN_REVIEW = 1,
  APPROVED = 2,
  WAITING_FOR_REPLY = 3,
  NOT_APPROVED = 4,
}
export enum SEARCH_COMPONENTS {
  type = "type",
  internalReference = "internalReference",
  componentName = "componentName",
  manufacturer = "manufacturer",
}
export enum DOCUMENTMATERIALS {
  type = "type2",
  sourceName = "type",
  mpn = "mpn",
  manufacturer = "manufacturer",
  series = "rohs_series",
}

export enum DOCUMENTCERTIFICATES {
  source = "serviceName",
  referenceNumber = "referenceNumber",
  type = "rawVersion",
  manufacturer = "manufacture",
}

export enum ROHS_COMPONENT_STATUSES {
  IN_REVIEW = 1,
  APPROVED = 2,
  WAITING_FOR_REPLY = 3,
  FLAGGED = 4,
  SOON_OUTDATED = 5,
  OUTDATED = 6,
  PENDING = 7,
  NOT_APPROVED = 8,
}

export enum DOCUMENT_TYPE {
  ROHS = "rohs",
  CERTIFICATE = "cert",
}

export enum ROLES {
  ADMIN = 1,
  USER = 2,
  COMPLIANCE = 3,
  DEVELOPER = 4,
}

export enum ROLES_NAMES {
  ADMIN = "Admin",
  USER = "User",
  COMPLIANCE = "Compliance",
  DEVELOPER = "Engineer",
}
