import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../AddProduct.scss";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import AddProductProgressBar, {
  PROGRESS_STATUS,
} from ".././AddProductProgressBar";
import { parsedFileDataType } from "./AddProduct2Step";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import SelectList from "../../selectList/SelectList";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import { selectCustomStyles } from "../../../constants/selectStyles";
const FormattedText = ({ cell, setParsedFileData, parsedFileData }: any) => {
  const id = cell._cell.row.data.id;
  const [value, setValue] = useState(cell.getValue());

  const maxLength = 18;
  return (
    <p>
      {value?.length > maxLength
        ? `${value.substring(0, maxLength)}...`
        : value}
    </p>
  );
};

const FormattedInput = ({ cell, setParsedFileData, parsedFileData }: any) => {
  const id = cell._cell.row.data.id;
  const [value, setValue] = useState(cell.getValue());
  console.log(parsedFileData, "parsedFileData step 3");
  const changeHandler = (parsedFileData: any, value: string) => {
    if (parsedFileData) {
      let index = parsedFileData.materials
        .map((item: any) => item.id)
        .indexOf(id);

      setParsedFileData((prev: any) => ({
        ...prev,
        materials: [
          ...prev.materials.slice(0, index),
          { ...parsedFileData.materials[index], componentName: value },
          ...prev.materials.slice(index + 1),
        ],
      }));
    }
  };

  const debouncedChangeHandler = useCallback(
    _.debounce(changeHandler, 800),
    []
  );

  const handleOnChange = (value: string) => {
    setValue(value);
    debouncedChangeHandler(parsedFileData, value);
  };

  return (
    <input
      className="InputTable"
      width={20}
      value={value}
      onChange={(e: any) => handleOnChange(e.target.value)}
    />
  );
};

const CriticalCheckbox = ({ cell, setParsedFileData, parsedFileData }: any) => {
  const id = cell._cell.row.data.id;
  const [value, setValue] = useState(cell.getValue());

  const onChange = (value: boolean) => {
    setValue(value);
    if (parsedFileData) {
      let index = parsedFileData.materials
        .map((item: any) => item.id)
        .indexOf(id);
      setParsedFileData((prev: any) => ({
        ...prev,
        materials: [
          ...prev.materials.slice(0, index),
          { ...parsedFileData.materials[index], critical: value },
          ...prev.materials.slice(index + 1),
        ],
      }));
    }
  };

  return (
    <input
      className="text-green-600 focus:ring-green-600"
      type="checkbox"
      checked={value}
      onChange={() => onChange(!value)}
    />
  );
};

const FormattedSelectList = ({
  cell,
  setParsedFileData,
  parsedFileData,
  suggestedCerts,
  requiredCerts,
}: any) => {
  const componentName = cell._cell.row.data.componentName;
  const id = cell._cell.row.data.id;
  const [values, setValues] = useState([]);

  let suggestedCertsOptions = requiredCerts?.map((certificat: any) => ({
    label: certificat.title,
    value: certificat.title,
  }));

  useEffect(() => {
    if (parsedFileData) {
      const neededMaterials = parsedFileData.materials.find(
        (item: any) => item.id === id
      );
      const neededMaterialsCertificates =
        neededMaterials?.requiredCertificates || [];
      setValues(
        neededMaterialsCertificates.map((item: string) => ({
          value: item,
          label: item,
        }))
      );
    }
  }, [parsedFileData]);

  if (!parsedFileData) {
    return <div></div>;
  }

  function handleChange(e: any) {
    const newArr = e.map(
      (item: { label: number; value: string; __isNew__: boolean }) => item.label
    );
    if (parsedFileData) {
      let index = parsedFileData.materials
        .map((item: any) => item.id)
        .indexOf(id);

      setParsedFileData((prev: any) => ({
        ...prev,
        materials: [
          ...prev.materials.slice(0, index),
          {
            ...parsedFileData.materials[index],
            requiredCertificates: newArr,
          },
          ...prev.materials.slice(index + 1),
        ],
      }));
    }
  }

  return (
    <div>
      <CreatableSelect
        isMulti={true}
        styles={selectCustomStyles}
        options={suggestedCertsOptions}
        value={values || []}
        onChange={handleChange}
        placeholder="Select a certificate"
      />
    </div>
  );
};
// const RecommendedSelectList = ({
//   cell,
//   setParsedFileData,
//   parsedFileData,
// }: any) => {
//   const id = cell._cell.row.data.id;
//   // Find the specific material object based on id
//   const material = parsedFileData?.materials.find(
//     (item: any) => item.id === id
//   );

//   // If material is not found or recommendation is not defined, return an empty div
//   if (!material || !material.recommendation) {
//     return <div></div>;
//   }

//   // Prepare options with grouped recommendations
//   const options = material.recommendation.map((recommendedItem: string) => ({
//     value: recommendedItem,
//     label: recommendedItem,
//   }));
//   const handleChange = (selectedOption: any) => {
//     if (parsedFileData) {
//       const index = parsedFileData.materials.findIndex(
//         (item: any) => item.id === id
//       );

//       if (index !== -1) {
//         const updatedMaterials = [...parsedFileData.materials];
//         updatedMaterials[index].componentName = selectedOption.value; // Assuming the value should be used here
//         setParsedFileData((prev: any) => ({
//           ...prev,
//           materials: updatedMaterials,
//         }));
//       }
//     }
//   };
//   return (
//     <div>
//       <CreatableSelect
//         // styles={selectCustomStyles}
//         options={options}
//         onChange={handleChange}
//         // Any other props you want to set for the CreatableSelect component
//       />
//     </div>

//   );
// };

const SuggestedNameSelectList = ({
  cell,
  setParsedFileData,
  parsedFileData,
  formData, // Assuming formData is available in your context
}: any) => {
  const id = cell._cell.row.data.id;
  const material = parsedFileData?.materials.find(
    (item: any) => item.id === id
  );
  console.log(material.recommendation, "material.recommendation");

  if (!material || !material.recommendation) {
    return <div></div>;
  }

  // let options = material.recommendation.map((recommendedItem:any) => recommendedItem.name);
  // const uniqueOptions = new Set(options);
  // options= Array.from(uniqueOptions);
  // let options = material.recommendation
  // .map((recommendedItem: any) => recommendedItem.name)
  // .filter((option:any) => option !== null && option?.trim() !== '');
  let options = material.recommendation.map((recommendedItem: any) => {
    return `${recommendedItem.ManufacturerPartNumber || ""}|${
      recommendedItem.Manufacturer || ""
    }`;
  });

  // const uniqueOptions = new Set(options);
  // options = Array.from(uniqueOptions);

  // console.log(options,'options')
  const handleChange = (selectedOption: any) => {
    console.log(selectedOption, "selectedOption");
    // console.log(id,'id')
    if (parsedFileData) {
      const index = parsedFileData.materials.findIndex(
        (item: any) => item.id === id
      );

      if (index !== -1) {
        const updatedMaterials = [...parsedFileData.materials];

        const foundObject = updatedMaterials[index].recommendation.find(
          (item: any) =>
            `${item.ManufacturerPartNumber || ""}|${
              item.Manufacturer || ""
            }` === selectedOption.value
        );

        console.log(foundObject, "datasheet found");
        if (foundObject) {
          updatedMaterials[index].oldComponentName =
            updatedMaterials[index].componentName;
          updatedMaterials[index].componentName =
            foundObject.ManufacturerPartNumber;
          updatedMaterials[index].type = foundObject.type;
          updatedMaterials[index].manufacturer = foundObject.Manufacturer;
          updatedMaterials[index].isSuggestionSelected = true;
          updatedMaterials[index].datasheetURL = foundObject.DataSheetUrl;
        } else {
          updatedMaterials[index].type = "";
          updatedMaterials[index].manufacturer = "";
        }
        setParsedFileData((prev: any) => ({
          ...prev,
          materials: updatedMaterials,
        }));
      }
    }
  };

  return (
    <div className="w-80">
      <SelectList
        placeholder="Select an option" // Update the placeholder
        options={options.map((option: string, index: number) => {
          console.log(option, "options are here");

          return {
            value: option,
            label: option,
            index: index,
          };
        })}
        dropDownHeight={150}
        selectHeight="large"
        onChange={(selectedOption: any) => handleChange(selectedOption)}
        value={{
          value: material.componentName,
          label: `${material.componentName || ""}|${
            material.manufacturer || ""
          }`,
        }}
      />
    </div>
  );
};
// const SuggestedTypeSelectList = ({
//   cell,
//   setParsedFileData,
//   parsedFileData,
//   formData,
// }: any) => {
//   const id = cell._cell.row.data.id;
//   const material = parsedFileData?.materials.find(
//     (item: any) => item.id === id
//   );

//   if (!material || !material.recommendation) {
//     return <div></div>;
//   }

// let options = material.recommendation.map((recommendedItem:any) => recommendedItem.type);
// const uniqueOptions = new Set(options);
// options= Array.from(uniqueOptions);
// let options = material.recommendation
//   .map((recommendedItem: any) => recommendedItem.type)
//   .filter((option:any) => option !== null && option?.trim() !== '');

// const uniqueOptions = new Set(options);
// options = Array.from(uniqueOptions);

//   const handleChange = (selectedOption: any) => {
//     if (parsedFileData) {
//       const index = parsedFileData.materials.findIndex(
//         (item: any) => item.id === id
//       );

//       if (index !== -1) {
//         const updatedMaterials = [...parsedFileData.materials];
//         updatedMaterials[index].type = selectedOption.value;
//         const foundObject = updatedMaterials[index].recommendation.find((item:any)=> item.type === selectedOption.value);

// if (foundObject) {
//   updatedMaterials[index].componentName =foundObject.name
//   updatedMaterials[index].manufacturer = foundObject.manufacturer
// } else {
//   updatedMaterials[index].componentName =''
//   updatedMaterials[index].manufacturer = ''
// }
//         setParsedFileData((prev: any) => ({
//           ...prev,
//           materials: updatedMaterials,
//         }));
//       }
//     }
//   };

//   return (
//     <div>
//       <SelectList
//         placeholder="Select an option" // Update the placeholder
//         options={options.map((option: string,index:number) => ({
//           value: option,
//           label: option,
//           index:index
//         }))}
//         dropDownHeight={150}
//         selectHeight='large'
//         onChange={(selectedOption: any) => handleChange(selectedOption)}
//         value={
//           material.type
//             ? {
//                 value: material.type,
//                 label: material.type,
//               }
//             : undefined
//         }
//       />
//     </div>
//   );
// };
// const SuggestedManufacturerSelectList = ({
//   cell,
//   setParsedFileData,
//   parsedFileData,
//   formData,
// }: any) => {
//   const id = cell._cell.row.data.id;
//   const material = parsedFileData?.materials.find(
//     (item: any) => item.id === id
//   );

//   if (!material || !material.recommendation) {
//     return <div></div>;
//   }

// let options = material.recommendation.map((recommendedItem: any) => recommendedItem.manufacturer);

// let options = material.recommendation.map((recommendedItem:any) => recommendedItem.manufacturer);
// const uniqueOptions = new Set(options);
// options= Array.from(uniqueOptions);

// commented
//   let options = material.recommendation
//   .map((recommendedItem: any) => recommendedItem.manufacturer)
//   .filter((option:any) => option !== null && option?.trim() !== '');
// ----------------------------------------------------------------------
// const uniqueOptions = new Set(options);
// options = Array.from(uniqueOptions);
//   const handleChange = (selectedOption: any) => {

//     if (parsedFileData) {
//       const index = parsedFileData.materials.findIndex(
//         (item: any) => item.id === id
//       );

//       if (index !== -1) {
//         const updatedMaterials = [...parsedFileData.materials];
//         updatedMaterials[index].manufacturer = selectedOption.value;
//         const foundObject = updatedMaterials[index].recommendation.find((item:any)=> item.manufacturer === selectedOption.value);

//         if (foundObject) {
//           updatedMaterials[index].componentName =foundObject.name
//           updatedMaterials[index].type = foundObject.type
//         } else {
//           updatedMaterials[index].componentName =''
//           updatedMaterials[index].type = ''
//         }
//         setParsedFileData((prev: any) => ({
//           ...prev,
//           materials: updatedMaterials,
//         }));
//       }
//     }
//   };

//   return (
//     <div>
//       <SelectList
//         placeholder="Select an option" // Update the placeholder
//         options={options.map((option: string,index:number) => ({
//           value: option,
//           label: option,
//           index:index
//         }))}
//         dropDownHeight={150}
//         selectHeight='large'
//         onChange={(selectedOption: any) => handleChange(selectedOption)}
//         value={
//           material.manufacturer
//             ? {
//                 value: material.manufacturer,
//                 label: material.manufacturer,
//               }
//             : undefined
//         }
//       />
//     </div>
//   );
// };

const FormattedStandardList = ({
  cell,
  setParsedFileData,
  parsedFileData,
  suggestedCerts,
}: any) => {
  const standard = cell._cell.row.data.standard;
  const componentName = cell._cell.row.data.componentName;
  const id = cell._cell.row.data.id;
  const [values, setValues] = useState([]);

  let suggestedStandardsOptions = suggestedCerts?.[
    componentName
  ]?.certificates.map((certificat: any) => ({
    label: certificat.standard,
    value: certificat.id,
  }));

  if (
    !suggestedCerts?.[componentName]?.certificates?.length ||
    !parsedFileData
  ) {
    return <div></div>;
  }

  const handleChange = (option: any) => {
    let index = parsedFileData.materials
      .map((item: any) => item.id)
      .indexOf(id);
    let localParsedFile = { ...parsedFileData };
    localParsedFile.materials[index].standard = option;
    setParsedFileData({ ...parsedFileData, localParsedFile });
  };

  return (
    <div>
      <SelectList
        isMulti={true}
        options={suggestedStandardsOptions}
        value={values}
        onChange={handleChange}
      />
    </div>
  );
};

type AddProduct3StepType = {
  parsedFileData: parsedFileDataType | null;
  setParsedFileData: any;
  suggestedCerts: any;
  requiredCerts: any;
};

const AddProduct3Step = (props: AddProduct3StepType) => {
  const { parsedFileData, setParsedFileData, suggestedCerts, requiredCerts } =
    props;
  const [tableData, setTableData] = useState<any>();
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    // Perform any actions you need when the page changes
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * 5,
    currentPage * 5
  );
  const defaultTableColumns = useMemo(
    () => [
      {
        field: "componentName",
        title: "COMPONENT NAME",
        id: "id",
        maxWidth: 50,

        formatter: reactFormatter(
          <FormattedInput
            setParsedFileData={setParsedFileData}
            parsedFileData={parsedFileData}
          />
        ),
      },
      {
        field: "type",
        title: "TYPE",
        id: "id",
        formatter: reactFormatter(
          <FormattedText
            setParsedFileData={setParsedFileData}
            parsedFileData={parsedFileData}
          />
        ),
      },
      {
        field: "manufacturer",
        title: "manufacturer",
        id: "id",
        width: 50,
        formatter: reactFormatter(<FormattedText />),
      },
      {
        field: "critical",
        title: "Critical",
        id: "id",
        layout: "fitColumns",
        formatter: reactFormatter(
          <CriticalCheckbox
            setParsedFileData={setParsedFileData}
            parsedFileData={parsedFileData}
          />
        ),
      },
      // {
      //   title: "SUGGESTED NAME",
      //   field: "suggestion",
      //   hozAlign: "left",
      //   width: 800,
      //   minWidth: 800,
      //   // layout: "fitColumns",
      //   formatter: reactFormatter(
      //     <SuggestedNameSelectList
      //       setParsedFileData={setParsedFileData}
      //       parsedFileData={parsedFileData}
      //     />
      //   ),
      // },
      // {
      //   title: "SUGGESTED Type",
      //   field: "suggestedType",
      //   hozAlign: "right",
      //   layout: "fitColumns",
      //   width: 400,
      //   formatter: reactFormatter(
      //     <SuggestedTypeSelectList
      //       setParsedFileData={setParsedFileData}
      //       parsedFileData={parsedFileData}
      //     />
      //   ),
      // },
      // {
      //   title: "SUGGESTED MANUFACTURER",
      //   field: "suggestedManufacturer",
      //   hozAlign: "right",
      //   layout: "fitColumns",
      //   width: 400,
      //   formatter: reactFormatter(
      //     <SuggestedManufacturerSelectList
      //       setParsedFileData={setParsedFileData}
      //       parsedFileData={parsedFileData}
      //     />
      //   ),
      // },
      // {
      //   title: "REQUIRED CERTIFICATES",
      //   field: "certificates",
      //   hozAlign: "left",
      //   // layout: "fitColumns",

      //   formatter: reactFormatter(
      //     <FormattedSelectList
      //       setParsedFileData={setParsedFileData}
      //       parsedFileData={parsedFileData}
      //       suggestedCerts={suggestedCerts}
      //       requiredCerts={requiredCerts}
      //     />
      //   ),
      // },
    ],
    [parsedFileData, setParsedFileData, suggestedCerts]
  );

  useEffect(() => {
    // const tempTableColumns = defaultTableColumns.map((tableColumn: any) => ({
    //   field: tableColumn.field,
    //   title: tableColumn.title,
    //   formatter: tableColumn.formatter ? tableColumn.formatter : "plaintext",
    //   hozAlign: tableColumn.hozAlign ? tableColumn.hozAlign : "left",
    //   // width:0,
    //   headerSort: false,
    //   resizable: true,
    // }));
    const tempTableColumns = defaultTableColumns.map((tableColumn: any) => {
      const commonColumnProps = {
        field: tableColumn.field,
        title: tableColumn.title,
        formatter: tableColumn.formatter ? tableColumn.formatter : "plaintext",
        hozAlign: tableColumn.hozAlign ? tableColumn.hozAlign : "left",
        headerSort: false,
        resizable: true,
      };

      // Check if the field is "certificates" and set a specific width
      if (tableColumn.field === "componentName") {
        return {
          ...commonColumnProps,
          width: 380,
        };
      }
      if (tableColumn.field === "type") {
        return {
          ...commonColumnProps,
          width: 280,
        };
      }
      if (tableColumn.field === "manufacturer") {
        return {
          ...commonColumnProps,
          width: 280,
        };
      }
      // if (tableColumn.field === "suggestion") {
      //   return {
      //     ...commonColumnProps,
      //     width: 350,
      //   };
      // }
      if (tableColumn.field === "certificates") {
        return {
          ...commonColumnProps,
          width: 210,
        };
      }
      if (tableColumn.field === "critical") {
        return {
          ...commonColumnProps,
          width: 190,
        };
      }

      return commonColumnProps;
    });
    setTableColumns(tempTableColumns);
    // const updateData = parsedFileData?.materials.map((material) => {
    //   return {
    //     ...material,
    //     recommendation: ['first', 'second', 'third', 'fourth']
    //   };
    // });
    // console.log(updateData)
    // setTableData(updateData);
    // console.log(parsedFileData?.materials)
    setTableData(parsedFileData?.materials);
  }, [parsedFileData, suggestedCerts]);
  // const showPagesCount = (pageCount: number, currentPage: number) => {
  //   let array: number[] = Array.from(
  //     new Set([
  //       1,
  //       2,
  //       3,
  //       currentPage < 2 ? 1 : currentPage - 1,
  //       currentPage,
  //       currentPage >= pageCount ? pageCount : currentPage + 1,
  //       pageCount - 2,
  //       pageCount - 1,
  //       pageCount,
  //     ])
  //   )
  //     .sort((a, b) => a - b)
  //     .filter((el) => el > 0 && el <= pageCount);
  //   for (let i = 1; i < array.length; i++) {
  //     if (array[i] - 1 !== array[i - 1]) {
  //       array = [...array.slice(0, i), 0, ...array.slice(i)];
  //       i++;
  //     }
  //   }
  //   return array;
  // };
  return (
    <>
      <AddProductProgressBar
        firstStatus={PROGRESS_STATUS.done}
        secondStatus={PROGRESS_STATUS.done}
        thirdStatus={PROGRESS_STATUS.active}
      />
      <div>
        <ReactTabulator
          columns={tableColumns}
          data={paginatedData}
          options={{
            layout: "fitColumns",
          }}
          data-custom-attribute="foo"
        />
        {/* <div>
        <p>Current Page: {currentPage}</p>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous Page
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(tableData?.length / 5)} // Adjust the condition based on the total number of pages
        >
          Next Page
        </button>
      </div> */}
        <div className="justify-end">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm my-2 "
            aria-label="Pagination"
          >
            <button
              onClick={() => handlePageChange(1)}
              className={`relative inline-flex rounded-md items-center m-1 px-2 py-2 border ${
                currentPage === 1 ? "text-gray-400" : "text-gray-500"
              } border-gray-300 bg-white text-sm font-medium hover:bg-gray-50`}
            >
              <span>First</span>
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex rounded-md items-center m-1 px-2 py-2 border ${
                currentPage === 1 ? "text-gray-400" : "text-gray-500"
              } border-gray-300 bg-white text-sm font-medium hover:bg-gray-50`}
            >
              <span>Prev</span>
            </button>
            <button className="relative inline-flex  rounded-md m-1  px-2 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span>{currentPage}</span>
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(tableData?.length / 5)}
              className={`relative inline-flex rounded-md items-center m-1 px-2 py-2 border ${
                currentPage === Math.ceil(tableData?.length / 5)
                  ? "text-gray-400"
                  : "text-gray-500"
              } border-gray-300 bg-white text-sm font-medium hover:bg-gray-50`}
            >
              <span>Next</span>
            </button>
            <button
              onClick={() => handlePageChange(Math.ceil(tableData?.length / 5))}
              className={`relative inline-flex rounded-md items-center m-1 px-2 py-2 border ${
                currentPage === Math.ceil(tableData?.length / 5)
                  ? "text-gray-400"
                  : "text-gray-500"
              } border-gray-300 bg-white text-sm font-medium hover:bg-gray-50`}
            >
              <span>Last</span>
            </button>
          </nav>
        </div>
      </div>
    </>
  );
};

export default AddProduct3Step;
